import { theme } from '@faxi/web-component-library';
import { flex, laptop, mobile, size, tablet } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

const STEP_DIAMETER = '50px';
const STEP_RADIUS = '25px';
const STEP_DIAMETER_MOBILE = '30px';
const STEP_RADIUS_MOBILE = '15px';

const STEP_LINE_SPACING = `${theme.sizes.SIZE_16}`;
const STEP_LINE_SPACING_MOBILE = `${theme.sizes.SIZE_8}`;

export const Card = styled.div`
  ${flex('column', 'center', 'center')}

  color: var(--SECONDARY_1_1);
  background-color: var(--SHADE_1_1);
  padding: ${theme.sizes.SIZE_64} ${theme.sizes.SIZE_32};
  text-align: center;
  border-radius: 6px;
  width: ${theme.sizes.SIZE_320};
  position: relative;

  .info-card {
    &__title {
      font-size: ${theme.fontSizes.FONT_28};
      font-weight: 600;
      margin: 0;
      margin-bottom: ${theme.sizes.SIZE_48};
    }

    &__description {
      font-size: ${theme.fontSizes.FONT_22};
      margin: auto;
      width: 100%;
    }

    &__icon {
      margin-bottom: ${theme.sizes.SIZE_32};
      svg {
        ${size(theme.sizes.SIZE_40)}
      }
    }

    &__step {
      ${flex('row', 'center', 'center')};
      ${size(STEP_DIAMETER)};

      border-radius: 50%;
      background-color: var(--PRIMARY_1_1);
      font-size: ${theme.fontSizes.FONT_24};
      margin-bottom: ${theme.sizes.SIZE_40};

      &::after {
        content: '';
        display: block;
        height: ${theme.sizes.SIZE_2};
        background-color: var(--PRIMARY_1_1);
        width: calc(100% - ${STEP_DIAMETER});
        left: calc(50% + ${STEP_RADIUS} + ${STEP_LINE_SPACING});
        position: absolute;
      }
    }
  }

  ${laptop(css`
    width: ${theme.sizes.SIZE_304};

    .info-card {
      &__title {
        font-size: ${theme.fontSizes.FONT_24};
      }

      &__description {
        font-size: ${theme.fontSizes.FONT_20};
      }
    }
  `)}

  ${tablet(css`
    .info-card {
      &__title {
        font-size: ${theme.fontSizes.FONT_20};
      }

      &__description {
        font-size: ${theme.fontSizes.FONT_18};
        max-width: 70%;
      }
    }
  `)}

  ${mobile(css`
    ${flex('column', 'flex-start', 'flex-start')}

    width: 100%;
    padding: ${theme.sizes.SIZE_16} ${theme.sizes.SIZE_12};
    margin-bottom: ${theme.sizes.SIZE_24};
    text-align: left;

    .info-card {
      &__icon {
        margin-left: ${theme.sizes.SIZE_64};
        margin-bottom: ${theme.sizes.SIZE_16};

        svg {
          ${size(theme.sizes.SIZE_32)};
        }
      }

      &__title {
        margin-left: ${theme.sizes.SIZE_64};
        margin-bottom: ${theme.sizes.SIZE_16};
      }

      &__description {
        margin-left: ${theme.sizes.SIZE_64};
      }

      &__step {
        ${size(STEP_DIAMETER_MOBILE)};
        font-size: ${theme.fontSizes.FONT_14};
        order: -1;
        margin-bottom: -${theme.sizes.SIZE_32};

        &::after {
          content: '';
          display: block;
          background-color: var(--PRIMARY_1_1);
          width: ${theme.sizes.SIZE_2};
          height: calc(
            100% - ${STEP_RADIUS_MOBILE} - ${STEP_LINE_SPACING_MOBILE}
          );
          left: calc(${STEP_RADIUS_MOBILE} + ${theme.sizes.SIZE_12});
          top: calc(
            ${STEP_DIAMETER_MOBILE} + ${STEP_LINE_SPACING_MOBILE} +
              ${theme.sizes.SIZE_16}
          );
          position: absolute;
        }
      }
    }
  `)}

  &.info-card--last {
    .info-card__step {
      &::after {
        display: none;
      }
    }
  }

  &.info-card--criteria {
    width: ${theme.sizes.SIZE_374};

    .info-card {
      &__title {
        margin-bottom: ${theme.sizes.SIZE_12};
      }

      &__icon {
        svg {
          ${size(theme.sizes.SIZE_64)};
        }

        margin-bottom: ${theme.sizes.SIZE_20};
      }

      &__descriptions {
        margin-top: ${theme.sizes.SIZE_32};
        align-self: flex-start;
        flex-grow: 1;
        padding: 0;

        &__item {
          ${flex('row', 'flex-start', 'flex-start')};

          &:not(:last-of-type) {
            margin-bottom: ${theme.sizes.SIZE_20};
          }

          text-align: left;

          p {
            margin: 0;
            font-size: ${theme.fontSizes.FONT_20};
          }

          svg {
            ${size(theme.sizes.SIZE_24)};

            margin-right: ${theme.sizes.SIZE_10};

            .fa-secondary {
              color: var(--PRIMARY_1_1);
              opacity: 1;
            }

            .fa-primary {
              color: var(--SECONDARY_1_1);
            }
          }
        }
      }
    }

    ${laptop(css`
      padding: ${theme.sizes.SIZE_48} ${theme.sizes.SIZE_24};
      height: auto;

      .info-car {
        &__icon {
          svg {
            ${size(theme.sizes.SIZE_48)};
          }
        }

        &__descriptions {
          p {
            font-size: ${theme.fontSizes.FONT_18};
          }
        }
      }
    `)}

    ${mobile(css`
      width: 100%;
      padding: ${theme.sizes.SIZE_24};

      .info-card {
        &__icon {
          margin: 0;
          svg {
            ${size(theme.sizes.SIZE_32)};
          }
        }
        &__title {
          margin-top: -${theme.sizes.SIZE_24};
          margin-bottom: 0;
        }

        &__descriptions {
          p {
            font-size: ${theme.fontSizes.FONT_16};
          }
        }
      }
    `)}
  }
`;
