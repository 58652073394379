import { Button, useCallbackRef } from '@faxi/web-component-library';
import {
  FormField,
  FormRef,
  useFormRefValues,
  validators,
} from '@faxi/web-form';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { InputField, SelectField, ValueCurrencyBox } from 'components';
import env from 'env';
import { AppraisalValuationType, ValueOfYourCarFormFields } from 'models';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import storageService from 'services';

import { formatMoneyValue } from '../../../../utils';
import { StyledValueOfYourCarForm } from './ValueOfYourCarForm.styled';
import { ConditionInfoModal, SetUpInfoModal } from './components';
import { useTranslation } from 'react-i18next';
import validation from '../../../../validation';

export type ValueOfYourCarFormProps = {
  changeStep: Dispatch<SetStateAction<number>>;
};

const conditionMapper: Record<
  string,
  { condition: string; condition_factor: number }
> = {
  fair: {
    condition: 'Usura e danni interni carrozzeria e/o meccanici',
    condition_factor: 25,
  },
  good: {
    condition: 'Buone condizioni con normali segni di utilizzo',
    condition_factor: 20,
  },
  excellent: {
    condition: 'Ottime condizioni, nessuna usura e/o danno meccanico',
    condition_factor: 17,
  },
};

const ValueOfYourCarForm: FC<ValueOfYourCarFormProps> = (props) => {
  const { changeStep } = props;

  const { t } = useTranslation();

  const [form, formRef] = useCallbackRef<FormRef>();

  const [formData, setFormData] = useState<AppraisalValuationType>();

  const formValues = useFormRefValues(
    form,
    'make',
    'model',
    'year',
    'mileage',
    'fuel_type',
    'set_up'
  ) as ValueOfYourCarFormFields;

  const [initialData, setInitialData] = useState<
    ValueOfYourCarFormFields | {}
  >();

  const [price, setPrice] = useState(
    Number(
      (
        storageService.getItem(
          env.VITE_FORM_STORAGE_KEY
        ) as ValueOfYourCarFormFields
      )?.price
    ) || 0
  );

  const {
    data: modelsData,
    isLoading: isLoadingModel,
  }: UseQueryResult<{ MODEL: string }[]> = useQuery({
    queryKey: ['distincts/MODEL', { MAKE: formValues?.make }],
    enabled: !!formValues?.make,
  });

  const modelDropdownOptions = useMemo(
    () =>
      modelsData?.map(({ MODEL }) => ({
        label: MODEL,
        value: MODEL,
      })) || [],
    [modelsData]
  );

  const {
    data: yearData,
    isLoading: isLoadingYear,
  }: UseQueryResult<{ YEAR: string }[]> = useQuery({
    queryKey: [
      'distincts/YEAR',
      { MAKE: formValues?.make, MODEL: formValues?.model },
    ],
    enabled: !!formValues?.make && !!formValues?.model,
  });

  const {
    data: fuelData,
    isLoading: isLoadingFuel,
  }: UseQueryResult<{ FUEL: string }[]> = useQuery({
    queryKey: [
      'distincts/FUEL',
      {
        MAKE: formValues?.make,
        MODEL: formValues?.model,
        YEAR: formValues?.year,
      },
    ],
    enabled: !!formValues?.make && !!formValues?.model && !!formValues?.year,
  });

  const {
    data: setupData,
    isLoading: isLoadingSetup,
  }: UseQueryResult<{ SETUP: string }[]> = useQuery({
    queryKey: [
      'distincts/SETUP',
      {
        MAKE: formValues?.make,
        MODEL: formValues?.model,
        YEAR: formValues?.year,
        FUEL: formValues?.fuel_type,
      },
    ],
    enabled:
      !!formValues?.make &&
      !!formValues?.model &&
      !!formValues?.year &&
      !!formValues?.fuel_type,
  });

  const fuelDropdownOptions = useMemo(
    () =>
      fuelData?.map(({ FUEL }) => ({
        label: FUEL,
        value: FUEL,
      })) || [],
    [fuelData]
  );

  const setupDropdownOptions = useMemo(
    () =>
      setupData?.map(({ SETUP }) => ({
        label: SETUP || '-',
        value: SETUP || '-',
      })) || [],
    [setupData]
  );

  const yearDropdownOptions = useMemo(
    () =>
      yearData?.map(({ YEAR }) => ({
        label: YEAR,
        value: YEAR,
      })) || [],
    [yearData]
  );

  const handleSubmit = useCallback(
    async ({
      make,
      model,
      year,
      mileage,
      fuel_type,
      set_up,
      condition,
    }: Omit<ValueOfYourCarFormFields, 'price'>) => {
      const mileage_from = Math.max(Number(mileage) - 15000, 0);

      try {
        setFormData({
          ACCOUNT: make?.toUpperCase(),
          MAKE: make,
          MODEL: model,
          YEAR: year,
          MILEAGE_FROM: mileage_from,
          MILEAGE_TO: Number(mileage) + 15000,
          SETUP: set_up === '-' ? '' : set_up,
          FUEL: fuel_type,
          CONDITION: conditionMapper[condition].condition,
          CONDITION_FACTOR: conditionMapper[condition].condition_factor,
        });

        Object.keys({
          make,
          model,
          year,
          mileage,
          fuel_type,
          set_up,
          condition,
        }).forEach((key) =>
          storageService.updateItem(
            key,
            { make, model, year, mileage, fuel_type, set_up, condition }?.[
              key as keyof Omit<ValueOfYourCarFormFields, 'price'>
            ]
          )
        );

        storageService.updateItem('activeStep', '2');
      } catch (e) {
        console.error(e);
      }
    },
    []
  );

  const resetPrice = useCallback(() => {
    if (!price) return;

    storageService.updateItem('price', '0');

    setPrice(0);
  }, [price]);

  const { data, isFetching, isFetched, isSuccess } = useQuery<{
    value: number;
    price: number;
  }>({
    queryKey: ['appraisal-valuation', formData],
    enabled: !!formData,
    staleTime: 0,
  });

  const validations = useMemo(
    () => ({
      mileage: [
        validators.general.required(
          t('ced-application-validation-name_required', {
            name: t('ced-application-tab1-mileage-label'),
          })
        ),
        validators.general.regex(
          validation.MILEAGE,
          t('ced-application-validation-name_valid', {
            name: t('ced-application-tab1-mileage-label').toLowerCase(),
          })
        ),
        validators.general.maxLength(
          7,
          t('ced-application-validation-length', { length: 7 })
        ),
      ],
    }),
    []
  );

  useEffect(() => {
    if (isFetched && isSuccess) {
      storageService.updateItem('price', `${data?.price || 0}`);
      storageService.updateItem('tan', '4.50 %');
      storageService.updateItem('taeg', '6.30 %');
    }
  }, [isFetched, isSuccess, data]);

  useEffect(() => {
    setInitialData(storageService.getItem(env.VITE_FORM_STORAGE_KEY) || {});
  }, []);

  useEffect(() => {
    if (data) {
      setPrice(Number(data.price));
    }
  }, [data]);

  return (
    <StyledValueOfYourCarForm
      onSubmit={handleSubmit}
      className="cedolauto-application__form"
      ref={formRef}
      initialData={initialData}
    >
      <div className="cedolauto-application__form__wrapper">
        <div className="cedolauto-application__form__wrapper__fields">
          <FormField
            component={SelectField}
            name="make"
            options={[
              { label: 'Toyota', value: 'Toyota' },
              { label: 'Lexus', value: 'Lexus' },
            ]}
            label={t('ced-application-tab1-make-label')}
            onChange={(ev) => {
              if (ev !== formValues?.make) {
                resetPrice();
                form.updateValueField('model', undefined);
                form.updateValueField('year', undefined);
                form.updateValueField('fuel_type', undefined);
                form.updateValueField('set_up', undefined);
                form.updateValueField('mileage', undefined);
                form.updateValueField('condition', undefined);
              }
            }}
          />

          <FormField
            component={SelectField}
            name="model"
            options={modelDropdownOptions}
            label={t('ced-application-tab1-model-label')}
            disabled={!formValues?.make}
            onChange={(ev) => {
              if (ev !== formValues?.model) {
                resetPrice();
                form.updateValueField('year', undefined);
                form.updateValueField('fuel_type', undefined);
                form.updateValueField('set_up', undefined);
                form.updateValueField('mileage', undefined);
                form.updateValueField('condition', undefined);
              }
            }}
            loading={isLoadingModel}
          />

          <FormField
            component={SelectField}
            name="year"
            options={yearDropdownOptions}
            label={t('ced-application-tab1-year-label')}
            disabled={!formValues?.model}
            onChange={(ev) => {
              if (ev !== formValues?.year) {
                resetPrice();
                form.updateValueField('fuel_type', undefined);
                form.updateValueField('set_up', undefined);
                form.updateValueField('mileage', undefined);
                form.updateValueField('condition', undefined);
              }
            }}
            loading={isLoadingYear}
          />

          <FormField
            component={SelectField}
            name="fuel_type"
            options={fuelDropdownOptions}
            label={t('ced-application-tab1-fuel-label')}
            disabled={!formValues?.year}
            onChange={(ev) => {
              if (ev !== formValues?.fuel_type) {
                resetPrice();
                form.updateValueField('set_up', undefined);
                form.updateValueField('mileage', undefined);
                form.updateValueField('condition', undefined);
              }
            }}
            loading={isLoadingFuel}
          />
        </div>

        <div className="cedolauto-application__form__wrapper__fields">
          <FormField
            component={SelectField}
            name="set_up"
            options={setupDropdownOptions}
            label={<SetUpInfoModal />}
            disabled={!formValues?.fuel_type}
            onChange={(ev) => {
              if (ev !== formValues?.set_up) {
                resetPrice();
                form.updateValueField('mileage', undefined);
                form.updateValueField('condition', undefined);
              }
            }}
            loading={isLoadingSetup}
          />

          <FormField
            component={InputField}
            name="mileage"
            label={t('ced-application-tab1-mileage-label')}
            disabled={!formValues?.set_up}
            onChange={(ev) => {
              if (ev !== formValues?.mileage) {
                resetPrice();
                form.updateValueField('condition', undefined);
              }
            }}
            validate={validations.mileage}
          />

          <FormField
            component={SelectField}
            name="condition"
            options={[
              {
                label: t('ced-application-condition-excellent'),
                value: 'excellent',
              },
              { label: t('ced-application-condition-good'), value: 'good' },
              { label: t('ced-application-condition-fair'), value: 'fair' },
            ]}
            label={<ConditionInfoModal />}
            disabled={!formValues?.mileage}
            onChange={() => {
              setTimeout(() => {
                const submit = document.getElementById('point-form-submit');

                (submit as HTMLButtonElement).click();
              }, 0);
            }}
          />

          <button hidden type="submit" id="point-form-submit" />
        </div>
      </div>

      <ValueCurrencyBox
        className="cedolauto-application__value-currency-box"
        title={t('ced-application-tab1-yourvalue')}
        value={isFetching ? `${t('ced-loading')}...` : formatMoneyValue(price)}
        {...(data?.price === 0 && { error: t('ced-application-tab1-error') })}
      />

      <Button
        variant="outline"
        className="cedolauto-application__form__submit"
        type="button"
        onClick={() => {
          changeStep((old) => old + 1);
        }}
        disabled={price === 0}
      >
        {t('ced-action-continue')}
      </Button>
    </StyledValueOfYourCarForm>
  );
};

export default ValueOfYourCarForm;
