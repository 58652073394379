import { theme } from '@faxi/web-component-library';
import { flex, phablet, position } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

const Header = styled.header`
  ${position('absolute', 'top 0 right 0 left 0')};
  ${flex('row', 'space-between', 'center')}
  padding: ${theme.sizes.SIZE_80};

  .cedolauto-header {
    &__logo {
      width: ${theme.sizes.SIZE_376};

      ${phablet(css`
        width: ${theme.sizes.SIZE_168};
      `)};
    }
  }

  ${phablet(css`
    padding: ${theme.sizes.SIZE_20};
    gap: ${theme.sizes.SIZE_40};
  `)};
`;

export { Header };
