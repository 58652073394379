import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  FormField,
  FormRef,
  validationRegexes,
  validators,
} from '@faxi/web-form';
import {
  Button,
  Icon,
  useCallbackRef,
  useUtilities,
} from '@faxi/web-component-library';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import { InputField } from 'components';
import storageService from 'services';
import env from 'env';
import { FindDealerFormFields, StorageApplicationFormType } from 'models';

import classNames from 'classnames';
import { breakTranslationNewlines } from '../../../../utils';

import { StyledFindDealerForm } from './FindDealerForm.styled';
import { useMutation } from '@tanstack/react-query';
import { defaultMutationFn } from '../../../../config/api/queryClient';
import validation from '../../../../validation';

export type FindDealerFormProps = {
  changeStep: Dispatch<SetStateAction<number>>;
};

const FindDealerForm: FC<FindDealerFormProps> = (props) => {
  const { changeStep } = props;

  const { t } = useTranslation();

  const [initialData, setInitialData] = useState<FindDealerFormFields | {}>();

  const [form, formRef] = useCallbackRef<FormRef>();

  const { prompts, showOverlay, hideOverlay } = useUtilities();

  const { mutateAsync } = useMutation<any, any, any>({
    mutationKey: ['save-offer'],
    mutationFn: (v) => defaultMutationFn('save-offer', v),
    onSuccess: () => {
      prompts.infoSuccess({
        title: t('ced-application-tab3-success-title'),
        submitBtnText: t('ced-action-close'),
        content: t('ced-application-tab3-success-description'),
        position: 'center',
      });

      storageService.removeItem(env.VITE_FORM_STORAGE_KEY);
      changeStep(1);
    },
  });

  const handleSubmit = useCallback(async (values: FindDealerFormFields) => {
    try {
      showOverlay('.cedolauto-application');
      Object.keys(values).forEach((key) => {
        storageService.updateItem(
          key,
          values?.[key as keyof FindDealerFormFields]
        );
      });

      const quotationID =
        +(
          storageService.getItem(
            env.VITE_FORM_STORAGE_KEY
          ) as StorageApplicationFormType
        )?.quotationID || 0;

      await mutateAsync({ ...values, quotationID });
    } catch (e) {
      console.error(e);
    } finally {
      hideOverlay('.cedolauto-application');
    }
  }, []);

  const validations = useMemo(
    () => ({
      firstName: [
        validators.general.required(
          t('ced-application-validation-name_required', {
            name: t('ced-application-tab3-first-name'),
          })
        ),
        validators.general.regex(
          validationRegexes.name,
          t('ced-application-validation-name_valid', {
            name: t('ced-application-tab3-first-name').toLowerCase(),
          })
        ),
        validators.general.maxLength(
          60,
          t('ced-application-validation-length', { length: 60 })
        ),
      ],
      lastName: [
        validators.general.required(
          t('ced-application-validation-name_required', {
            name: t('ced-application-tab3-last-name'),
          })
        ),
        validators.general.regex(
          validationRegexes.name,
          t('ced-application-validation-name_valid', {
            name: t('ced-application-tab3-last-name').toLowerCase(),
          })
        ),
        validators.general.maxLength(
          60,
          t('ced-application-validation-length', { length: 60 })
        ),
      ],
      email: [
        validators.general.required(
          t('ced-application-validation-name_required', {
            name: t('ced-application-tab3-email'),
          })
        ),
        validators.general.regex(
          validationRegexes.workEmail,
          t('ced-application-validation-name_valid', {
            name: t('ced-application-tab3-email').toLowerCase(),
          })
        ),
        validators.general.maxLength(
          80,
          t('ced-application-validation-length', { length: 80 })
        ),
      ],
      phoneNumber: [
        validators.general.required(
          t('ced-application-validation-name_required', {
            name: t('ced-application-tab3-phone'),
          })
        ),
        validators.phone.regex(
          validation.PHONE_NUMBER,
          t('ced-application-validation-name_valid', {
            name: t('ced-application-tab3-phone').toLowerCase(),
          })
        ),
      ],
      zipCode: [
        validators.general.required(
          t('ced-application-validation-name_required', {
            name: t('ced-application-tab3-zip'),
          })
        ),
        validators.phone.regex(
          validation.ZIP_CODE,
          t('ced-application-validation-name_valid', {
            name: t('ced-application-tab3-zip').toLowerCase(),
          })
        ),
      ],
    }),
    [t]
  );

  useEffect(() => {
    setInitialData(storageService.getItem(env.VITE_FORM_STORAGE_KEY) || {});
  }, []);

  return (
    <StyledFindDealerForm
      className={classNames(
        'cedolauto-find-dealer-form',
        'cedolauto-application__form'
      )}
      onSubmit={handleSubmit}
      initialData={initialData}
      ref={formRef}
    >
      <div className="cedolauto-application__form__wrapper">
        <div className="cedolauto-application__form__wrapper__fields">
          <FormField
            component={InputField}
            name="firstName"
            label={t('ced-application-tab3-first-name')}
            validate={validations.firstName}
          />
          <FormField
            component={InputField}
            name="lastName"
            label={t('ced-application-tab3-last-name')}
            validate={validations.lastName}
          />
          <FormField
            component={InputField}
            name="phoneNumber"
            label={t('ced-application-tab3-phone')}
            validate={validations.phoneNumber}
          />
          <FormField
            component={InputField}
            name="email"
            label={t('ced-application-tab3-email')}
            validate={validations.email}
          />
          <div className="cedolauto-find-dealer-form__input-">
            <FormField
              component={InputField}
              name="zipCode"
              label={t('ced-application-tab3-zip')}
              helperText={t('ced-application-tab3-zip-subtext')}
              validate={validations.zipCode}
            />
          </div>
        </div>

        <div className="cedolauto-find-dealer-form__desc">
          <p className="cedolauto-find-dealer-form__desc__title">
            {parse(
              breakTranslationNewlines(t('ced-application-tab3-info-title'))
            )}
          </p>
          <p className="cedolauto-find-dealer-form__desc__content">
            {parse(
              breakTranslationNewlines(t('ced-application-tab3-info-copy'))
            )}
          </p>
        </div>
      </div>

      <div className="cedolauto-find-dealer-form__actions">
        <Button
          variant="ghost"
          className="cedolauto-find-dealer-form__actions__prev-step"
          onClick={() => {
            changeStep((old) => old - 1);
          }}
          icon={<Icon name="chevron-down" />}
        >
          {t('ced-action-previous-step')}
        </Button>
        <Button
          className="cedolauto-application__form__submit"
          type="submit"
          disabled={!form?.isFormChanged() || !form?.syncFormValid}
        >
          {t('ced-action-submit')}
        </Button>
      </div>
    </StyledFindDealerForm>
  );
};

export default FindDealerForm;
