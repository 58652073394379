import { Button, Icon } from '@faxi/web-component-library';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import parse from 'html-react-parser';
import { Slider, ValueCurrencyBox } from 'components';
import storageService from 'services';
import env from 'env';
import { FinanceCalculationFormFields } from 'models';
import { StyledFinanceCalculationForm } from './FinanceCalculationForm.styled';
import classNames from 'classnames';
import { breakTranslationNewlines, formatMoneyValue } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { defaultMutationFn } from '../../../../config/api/queryClient';

export type FinanceCalculationFormProps = {
  changeStep: Dispatch<SetStateAction<number>>;
};

type FinanceApiData = {
  canone_mensile_lordo: number;
  tan: number;
  taeg: number;
  importo_residuo_lordo: number;
};

const FinanceCalculationForm: FC<FinanceCalculationFormProps> = (props) => {
  const { changeStep } = props;

  const { t } = useTranslation();

  const [initialData, setInitialData] =
    useState<FinanceCalculationFormFields>();

  const [term, setTerm] = useState<number>(
    +(
      storageService.getItem(
        env.VITE_FORM_STORAGE_KEY
      ) as FinanceCalculationFormFields
    )?.term || 24
  );

  const price = useMemo(
    () =>
      +(
        storageService.getItem(
          env.VITE_FORM_STORAGE_KEY
        ) as FinanceCalculationFormFields
      )?.price,
    []
  );

  const { data, mutateAsync, isPending } = useMutation<
    FinanceApiData,
    any,
    {
      duration: number;
      price: number;
    }
  >({
    mutationKey: ['create-offer'],
    mutationFn: (v) => defaultMutationFn('create-offer', v),
  });

  const handleSubmit = useCallback(async () => {
    try {
      if (!data) return;
      Object.keys(data).forEach((key) => {
        storageService.updateItem(
          key,
          data[key as keyof FinanceApiData].toString()
        );
      });

      storageService.updateItem('activeStep', '3');

      changeStep((old) => old + 1);
    } catch (e) {
      console.error(e);
    }
  }, [term, data]);

  useEffect(() => {
    setInitialData(
      storageService.getItem(env.VITE_FORM_STORAGE_KEY) ||
        ({} as FinanceCalculationFormFields)
    );
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(async () => {
      await mutateAsync({
        duration: term,
        price,
      });
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [term, mutateAsync]);

  const loadingText = useMemo(() => `${t('ced-loading')}...`, [t]);

  return (
    <StyledFinanceCalculationForm
      className={classNames(
        'cedolauto-finance-calculation-form',
        'cedolauto-application__form'
      )}
      onSubmit={handleSubmit}
      initialData={initialData}
    >
      <div className="cedolauto-application__form__wrapper">
        <div className="cedolauto-application__form__wrapper__fields">
          <div className="cedolauto-application__form__wrapper__fields__finance-data">
            {t('ced-application-tab1-title')}{' '}
            <span>{formatMoneyValue(Number(initialData?.price || 0))}</span>
          </div>
          <div className="cedolauto-application__form__wrapper__fields__finance-data">
            {t('ced-application-tab2-tan-label')}{' '}
            <span>{data?.tan ? data.tan.toFixed(2) : loadingText}</span>
          </div>
          <div className="cedolauto-application__form__wrapper__fields__finance-data">
            {t('ced-application-tab2-taeg-label')}{' '}
            <span>{data?.taeg ? data.taeg.toFixed(2) : loadingText}</span>
          </div>
          <div className="cedolauto-application__form__wrapper__fields__finance-data">
            {t('ced-application-tab2-residual-label')}
            <span>
              {data?.importo_residuo_lordo
                ? formatMoneyValue(data.importo_residuo_lordo)
                : loadingText}
            </span>
          </div>
        </div>

        <div className="cedolauto-finance-calculation-form__term">
          <div className="cedolauto-finance-calculation-form__term__title">
            {t('ced-apply-block2-title')}
          </div>
          <p className="cedolauto-finance-calculation-form__term__desc">
            {t('ced-application-tab2-term-copy')}
          </p>
          <div>
            <Slider
              value={term}
              step={12}
              onChange={async (e) => {
                if (!isPending) {
                  setTerm(+e.target.value);
                }
              }}
              min={12}
              max={60}
            />
            <div className="cedolauto-finance-calculation-form__term__value">
              {term} {t('ced-months')}
            </div>
          </div>
        </div>
      </div>
      <ValueCurrencyBox
        title={t('ced-application-tab2-cost')}
        value={
          data?.canone_mensile_lordo
            ? formatMoneyValue(data.canone_mensile_lordo)
            : loadingText
        }
        info={parse(
          breakTranslationNewlines(t('ced-application-tab2-cost-info'))
        )}
      />

      <div className="cedolauto-finance-calculation-form__actions">
        <Button
          variant="ghost"
          className="cedolauto-finance-calculation-form__actions__prev-step"
          onClick={() => {
            changeStep((old) => old - 1);
          }}
          icon={<Icon name="chevron-down" />}
        >
          {t('ced-action-previous-step')}
        </Button>

        <Button
          variant="outline"
          type="submit"
          className="cedolauto-application__form__submit"
          disabled={isPending}
        >
          {t('ced-action-continue')}
        </Button>
      </div>
    </StyledFinanceCalculationForm>
  );
};

export default FinanceCalculationForm;
